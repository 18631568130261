import .selectedFilter {
  filter: brightness(120%);
  transform: translateY(-3px);
}

.blackButton {
  padding: 12px 48px;
  gap: 10px;
  background: #333333;
  white-space: nowrap;

  /* Neutrals/WhiteWhite */
  border: 2px solid #ffffff;
  border-radius: 31px;
  /*transform: matrix(1, 0, 0, -1, 0, 0);*/
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.greenButton {
  padding: 16px 24px;
  gap: 10px;
  background: #198754;
  /* Neutrals/WhiteWhite */
  border: 2px solid #ffffff;
  border-radius: 31px;
  color: url('../images/backgrounds/pink_light_background_gradient_5.png');
  /*transform: matrix(1, 0, 0, -1, 0, 0);*/
}

.roundedFilterButton {
  padding: 4px 8px 4px 12px;
  gap: 8px;
  background: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 16px;
}

.eventItem,
.eventItemNonAdmin {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  gap: 24px;
  min-height: 92px;
  min-width: 100%;
  gap: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(51, 51, 51, 0.25),
    0px 0px 8px rgba(51, 51, 51, 0.1);
  border-radius: 4px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  overflow: auto;
  margin-bottom: 16px;
}

.eventInformation {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  gap: 24px;
  height: 100%;
}

.eventItemButton {
  gap: 10px;
  height: 100%;

  border: none;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.eventItemEdit {
  padding: 4px 16px;
  gap: 8px;
  color: #ffffff;
  background: #84c1d4;
  border: 2px solid #84c1d4;
  border-radius: 16px;
  min-width: 80px;
  min-height: 25px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  -webkit-box-flex: 1;
}

.eventItemShow {
  padding: 4px 16px;
  gap: 8px;
  border-radius: 16px;
  min-width: 80px;
  min-height: 34px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.goToEventButton {
  margin-right: 50px;
}

.eventArrowIcon {
  width: 16px;
  height: 16px;
}

.eventDate {
  /* max-width: 10%; */
  min-width: 50px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  color: #333333;
}

.eventDateDay {
  font-weight: 700;
  font-size: 40px;
}

.eventDateMonth {
  font-weight: 400;
  font-size: 16px;
}

.eventTitle {
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  cursor: pointer;
}

.eventDetailsLocation {
  min-width: 10vw;
}

.eventIcons {
  margin-right: 8px;
  color: #333333;
  margin-bottom: 4px;
}

.eventText {
  color: #333333;
  font-size: 16px;
}

.eventMonth {
  font-family: 'Trim';
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: uppercase;
  color: #f185ad;

  /* HiQ Pink/Muted pink */
}

.modal.center .modal-dialog {
  min-width: 50vw;
  /* -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0); */
  padding: (18px, 56px, 18px, 24px);
}

.eventInformationXS {
  margin-right: 3vw;
}

.eventItemButtonRow {
  margin-top: auto;
  margin-bottom: auto;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.eventTag {
  padding: 8px 12px;
  gap: 10px;
  min-width: 60px;
  min-height: 30px;
  background: #f0f0f0;
  border-radius: 10px;
  white-space: nowrap;
  color: #7b29a2;
  font-size: 16px;
}

.locationTag {
  padding: 8px 12px;
  min-width: 60px;
  min-height: 30px;
  max-height: 80px;
  max-width: 200px;
  border-radius: 10px;
  background: #f0f0f0;
}

@media only screen and (min-width: 1300px) {
  .hideLabel {
    margin-right: -2vw;
  }

  .allCategories {
    margin-right: -2vw;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 2500px) {
  .hideLabel {
    margin-right: -0.5vw;
  }

  .allCategories {
    margin-right: -0.5vw;
  }
}

@media only screen and (max-width: 576px) {
  .allCategories {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  .allMonths {
    text-align: left;
  }

  .eventList {
    text-align: center;
  }

  .eventTitle {
    font-size: 16px;
  }

  .recurringSwitch {
    margin-bottom: 12px;
  }

  .changeViewButton {
    float: none !important;
  }

  .eventDate {
    margin-bottom: auto;
    margin-top: 0;
  }

  .eventDateDay {
    font-size: 28px;
  }

  .eventDateMonth {
    font-size: 16px;
  }

  .eventItem,
  .eventItemNonAdmin {
    display: table;
    padding-top: 12px;
    padding-bottom: 8px;
    padding-left: 4px;
    padding-right: 4px;
    min-height: 92px;
    min-width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(51, 51, 51, 0.25),
      0px 0px 8px rgba(51, 51, 51, 0.1);
    border-radius: 4px;
    overflow: auto;
    margin-bottom: 16px;
  }

  .eventItemShow {
    margin-top: 4px;
    margin-bottom: 8px;
  }

  .goToEventButton {
    margin-right: 0px;
  }

  .eventInformationXS,
  .eventInformationXSAdmin {
    margin-left: -60px;
    margin-top: 50px;
    text-align: left;
  }

  .eventIcons {
    margin-right: 4px;
    color: #333333;
    margin-bottom: 0px;
  }

  .eventText {
    font-size: 16px;
  }

  .showEventSmallScreenCol {
    width: 66.6667%;
  }

  .eventItemButtonRow {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;
    width: 100%;
    text-align: center;
  }

  .eventTitle {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
  }

  .eventTag {
    padding: 16px 24px;
    gap: 10px;
    min-width: 70px;
    min-height: 35px;
    background: #f0f0f0;
    border-radius: 10px;
    white-space: nowrap;
    color: #7b29a2;
    font-size: 18px;
    margin-top: 100px;
  }

  .locationTag {
    padding: 16px 24px;
    border-radius: 10px;
    margin-top: 100px;
    background: #f0f0f0;
  }

  .eventTagArea {
    margin-top: 1rem;
    text-align: initial;
  }
}

@media only screen and (max-width: 768px) {
  .eventItem,
  .eventItemNonAdmin {
    display: table;
    padding-top: 12px;
    padding-bottom: 8px;
    padding-left: 4px;
    padding-right: 4px;
    min-height: 92px;
    min-width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(51, 51, 51, 0.25),
      0px 0px 8px rgba(51, 51, 51, 0.1);
    border-radius: 4px;
    overflow: auto;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .allCategories,
  .allMonths {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 820px) {
  .eventItem {
    overflow-x: hidden;
  }
}

@media only screen and (min-width: 992px) {
  .eventItem,
  .eventItemNonAdmin {
    overflow-x: hidden;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .eventDetails {
    min-width: fit-content;
    margin-right: 100px;
  }
}
