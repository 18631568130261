.calendarContainer {
   min-height: 75vh;
   width: 100%;
}

.fc-event.main {
   color: var(--fc-event-text-color, inherit);
}

.fc .fc-toolbar-title {
   font-size: 1.75em;
   margin: 0;
   color: #F185AD;
   text-transform: uppercase;
   font-family: 'Trim';
   font-size: xx-large;
}

.fc .fc-button {
   display: inline-block;
   font-weight: 400;
   text-align: center;
   vertical-align: middle;
   -webkit-user-select: none;
   user-select: none;
   padding: 8px 16px;
   font-size: 16px;
   line-height: 1.5;
   border-radius: 24px;
   background-color: #333333;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
   display: inline-block;
   font-weight: 400;
   text-align: center;
   vertical-align: middle;
   -webkit-user-select: none;
   user-select: none;
   padding: 8px 16px;
   font-size: 16px;
   line-height: 1.5;
   border-radius: 24px;
   background-color: #76818D;
   border-style: none;
}

.fc .fc-col-header-cell-cushion {
   display: inline-block;
   padding: 2px 4px;
   color: #333333;
}

.fc .fc-daygrid-day-number {
   position: relative;
   z-index: 4;
   padding: 4px;
   color: #333333;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
   margin-right: 2px;
   color: #333333;
   cursor: pointer;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
   box-shadow: 0px 0px 0px 1px #fff;
   box-shadow: 0px 0px 0px 1px var(--fc-page-bg-color, #fff);
   cursor: pointer;
}

.fc .fc-view-harness {
   background-color: #fff;
}

@media only screen and (max-width: 576px) {
   .fc .fc-toolbar-title {
      margin: 0;
      color: #F185AD;
      text-transform: uppercase;
      font-family: 'Trim';
      font-size: x-large;
      text-align: center;
      margin-left: 8px;
      margin-right: 8px;
   }

   .fc .fc-button {
      display: inline-block;
      font-weight: 400;
      text-align: center;
      -webkit-user-select: none;
      user-select: none;
      background-color: #333333;
      padding: 8px;
      font-size: 16px;
      min-width: 64px;
   }

   .fc .fc-button-primary:not(:disabled):active,
   .fc .fc-button-primary:not(:disabled).fc-button-active {
      display: inline-block;
      font-weight: 400;
      text-align: center;
      -webkit-user-select: none;
      user-select: none;
      background-color: #76818D;
      border-style: none;
      padding: 8px;
      font-size: 16px;
      min-width: 64px;
   }

   .fc-event {
      font-size: 10px;
   }

   .fc th {
      text-align: center;
      font-size: 10px;
   }

   .fc .fc-timegrid-axis-cushion,
   .fc .fc-timegrid-slot-label-cushion {
      padding: 0 4px;
      font-size: 16px;
   }

   .fc .fc-toolbar.fc-header-toolbar {
      width: 100%;
      display: flex;
      justify-content: center;
   }


}