.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 6.5vh;
    padding: 8px 24px 8px 24px;
}

.header-pageName {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
}

.headerTitle {
    font-family: 'MD Primer';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    /* identical to box height, or 150% */

    /* Neutrals/White */
    color: #FFFFFF;
    margin-top: auto;
    margin-bottom: auto;
}


.headerLoginArea {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
}

.headerLogin {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    /* identical to box height, or 150% */
    text-decoration-line: underline;

    /* Neutrals/White */
    color: #FFFFFF;
    border: none;
    width: -webkit-fill-available;
}

.fade-in-animation {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    display: inline-block;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @media only screen and (max-width: 576px) {
    .loginInputXS {
      min-width: 50px
    }

    .navbar-expand {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }