.EventForm-containter {
    overflow-y: auto;
    width: 80vw;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF;
    padding: 40px 80px;
    gap: 40px;
}

.standardSignUpContainer {
    overflow-y: auto;
    width: 80vw;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF;
    padding: 40px 80px;
    gap: 40px;
}

.mintSignUpContainer {
    overflow-y: auto;
    width: 80vw;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    background: url('../images/backgrounds/mint_background_gradient.png');
    /* background-repeat: no-repeat;
    background-size: 100% 100%; */
    padding: 40px 80px;
    gap: 40px;
}

.peachSignUpContainer {
    overflow-y: auto;
    width: 80vw;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    background: url('../images/backgrounds/peach_background_gradient.png');
    /* background-repeat: no-repeat;
    background-size: 100% 100%; */
    padding: 40px 80px;
    gap: 40px;
}

.pinkLightSignUpContainer {
    overflow-y: auto;
    width: 80vw;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    background: url('../images/backgrounds/pink_light_background_gradient.png');
    /* background-repeat: no-repeat;
    background-size: 100% 100%;    padding: 40px 80px; */
    gap: 40px;
}

.purpleSignUpContainer {
    overflow-y: auto;
    width: 80vw;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    /* background-repeat: no-repeat;
    background-size: 100% 100%; */
    padding: 40px 80px;
    gap: 40px;
}

.Form-input {
    text-align: center;
}

/* .EventForm-container-background {
    text-align: center;
    overflow-y: auto;
    background-image: url('https://vhotel.se/wp-content/uploads/2019/07/AFTER-WORK.jpg');
    height: 88vh;
    background-size: 100%;
    color: white;
    text-shadow:
        0.05em 0 black,
        0 0.05em black,
        -0.05em 0 black,
        0 -0.05em black,
        -0.05em -0.05em black,
        -0.05em 0.05em black,
        0.05em -0.05em black,
        0.05em 0.05em black;
} */

.eventSignUpTitle {
    font-size: 32px;
    font-weight: 700;
}

.eventInformation-area {
    width: 100%;
    margin-right: 24px;
}

.eventSignupDetails {
    margin-bottom: 24px;
}

.eventDetailIcons {
    margin-right: 8px;
    margin-bottom: 8px;
}

.eventDetailText {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
}

.eventSignUp-body {
    width: 100%;
    display: flex;
}

.eventSignUp-area {
    width: 100%;
    height: fit-content;
    gap: 24px;
}

.eventSignUpButton {
    padding: 12px 48px;
    gap: 10px;
    background: #FFFFFF;
    border: 2px solid #333333;
    border-radius: 31px;
    text-align: center;
    color: #333333;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
}

.eventSignUpDate {
    font-family: 'MD Primer';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.signUpHeader {
    font-weight: 700;
    font-size: 24px;
}

/* .outline-blackButton {
    padding: 12px 48px;
    gap: 10px;
    background: #FFFFFF;
    border: 2px solid #333333;
    border-radius: 31px;
    text-align: center;
    color: #333333;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.outline-blackButtonSmall {
    gap: 10px;
    background: #FFFFFF;
    border: 2px solid #333333;
    border-radius: 31px;
    text-align: center;
    color: #333333;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
} */

.eventSignUpImageArea {
    padding: 32px;
    max-width: 92.5%;
    min-width: 50%;
    height: fit-content;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.eventSignUpImage {
    height: fit-content;
    justify-content: center;
    min-width: 500px;
    max-width: 100%;
}

@media screen and (max-width: 1300px) {
    .eventSignUpButton {
        background: #FFFFFF;
        border: 2px solid #333333;
        text-align: center;
        color: #333333;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 32px;
    }

    .modalInput {
        margin-bottom: 8px;
    }

}

@media only screen and (max-width: 776px) {
    .standardSignUpContainer {
        width: 90%;
        text-align: center;
    }

    .eventInformation-area {
        width: 100%;
    }

    .eventSignUp-area {
        margin-top: 12px;
        width: 100%;
    }

    .eventSignUp-body {
        display: contents;
    }

    .eventSignUpButton {
        margin-bottom: 8px;
    }

    .eventSignUpImage {
        min-width: 220px;
    }

    .mintSignUpContainer,
    .peachSignUpContainer,
    .purpleSignUpContainer,
    .pinkLightSignUpContainer {
        padding: 0px;
    }

}