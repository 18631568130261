@font-face {
  font-family: MDP-Test;
  src: url('../public/fonts/MD Primer Test/MDPrimerTest-Regular.otf');
}

@font-face {
  font-family: MDP;
  src: url('../public/fonts/MD\ Primer/MDPrimer-Regular.otf');
}

.App-container {
  min-height: 100vh;
  background: url('./images/backgrounds/BG_gradient_1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: MDP;
}

.App-container-no-background {
  min-height: 100vh;
  background: url('./images/backgrounds/BG_gradient_1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: MDP;
}

.mintTheme {
  padding-top: 2.5vh;
  min-height: 100vh;
  font-family: MDP;
  background: url('./images/backgrounds/mint_background_gradient.png');
}

.peachTheme {
  padding-top: 2.5vh;
  min-height: 100vh;
  font-family: MDP;
  background: url('./images/backgrounds/peach_background_gradient.png');
}

.pinkLightTheme {
  padding-top: 2.5vh;
  min-height: 100vh;
  font-family: MDP;
  background: url('./images/backgrounds/pink_light_background_gradient.png');
}

.purpleTheme {
  padding-top: 2.5vh;
  min-height: 100vh;
  font-family: MDP;
  background: url('./images/backgrounds/purple_background_gradient.png');
}

.App-header {
  text-align: center;
  margin-top: 0vh;
  margin-bottom: 5vh;
  font-size: 400%;
}

.Document-container {
  height: 95vh;
  text-align: center;
}

.Document-header {
  margin-top: 30vh;
}

/* html {
  background: rgb(205, 190, 213);
  background-size: 100% 100%;
} */