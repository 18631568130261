.modal.left .modal-dialog {
    position: fixed;
    right: 0;
    margin: auto;
    min-width: 75vw;
    height: 100%;
    /* -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0); */
    padding: (18px, 56px, 18px, 24px);
    overflow-x: hidden;
}

.modal.fade .modal-dialog {
    transition: transform 0.5s ease-in-out;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    transition-property: transform;
}

.modal.left .modal-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    /* background: url('../../images/backgrounds/mint_background_gradient.png'); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.modal-footer {
    display: block;
}

.modal-body .modal-header {
    padding: (18px, 56px, 18px, 24px);
}

.modalDate {
    font-family: 'MD Primer';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.modalDivider {
    box-shadow: 0px -2px 8px rgba(51, 51, 51, 0.1);
}

.modalEventIcons {
    margin-right: 8px;
    margin-bottom: 4px;
}

.modalEventText {
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    line-height: 150%;
}

.modalEventDescription {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 1px;

    /* or 150% */

    /* Neutrals/HiQBlack */
    color: #333333;
}

.modalSignUpArea {
    gap: 24px;
    width: 92.5%;
    height: fit-content;
    border-radius: 12px;
    margin-top: 16px;
}

.outline-blackButton {
    background: #FFFFFF;
    border: 2px solid #333333;
    border-radius: 31px;
    text-align: center;
    color: #333333;
}

.outline-blackButtonSmall {
    background: #FFFFFF;
    border: 2px solid #333333;
    text-align: center;
    color: #333333;
}

.outline-blackButton-delete {
    background: #FFFFFF;
    border: 2px solid #333333;
    color: #333333;
}

.outline-redButton {
    background: #FFFFFF;
    border: 2px solid red;
    text-align: center;
    color: red;
}

.outline-redButton-delete {
    color: red;
    background: #FFFFFF;
    border: 2px solid red;
}

.modalLightBlueButton {
    padding: 4px 16px;
    gap: 8px;
    color: #FFFFFF;
    background: #84C1D4;
    border: 2px solid #84C1D4;
    border-radius: 16px;
    min-width: 111px;
    min-height: 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    -webkit-box-flex: 1
}

.roundedButton {
    padding: 4px 16px;
    gap: 8px;
    border-radius: 16px;
    min-width: 111px;
    min-height: 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.myModal .modal-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: url('../../images/backgrounds/mint_background_gradient.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.myModal2 .modal-content {
    background: url('../../images/backgrounds/BG_gradient_1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.mintModal .modal-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: url('../../images/backgrounds/mint_background_gradient.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

}

.mintThemeSelected {
    background: url('../../images/backgrounds/mint_background_gradient.png');
    cursor: pointer;
}

.peachModal .modal-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: url('../../images/backgrounds/peach_background_gradient.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.peachThemeSelected {
    background: url('../../images/backgrounds/peach_background_gradient.png');
    cursor: pointer;
}


/* .pinkModal .modal-content {
    background: url('../../images/backgrounds/pink_background_gradient.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
} */

.pinkLightModal .modal-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: url('../../images/backgrounds/pink_light_background_gradient.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.pinkLightThemeSelected {
    background: url('../../images/backgrounds/pink_light_background_gradient.png');
    cursor: pointer;
}


.purpleModal .modal-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: linear-gradient(90deg, #efd5ff 0%, #515ada 100%);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.purpleThemeSelected {
    background: url('../../images/backgrounds/purple_background_gradient.png');
    cursor: pointer;
}

.standardModal .modal-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #FFFFFF;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
    background-color: #FF66C0;
    border: none;
}

.modalInput {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(51, 51, 51, 0.25), 0px 0px 8px rgba(51, 51, 51, 0.25);
    border-radius: 8px;
    margin-top: 8px;
}

.modalInputSelect {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(51, 51, 51, 0.25), 0px 0px 8px rgba(51, 51, 51, 0.25);
    border-radius: 8px;
    margin-top: 8px;
}

.modalInput:active {
    background: #FFFFFF;

    /* Neutrals/Grey 900 (HiQBlack) (text) */
    border: 1px solid #333333;
    box-shadow: 0px 0px 1px rgba(51, 51, 51, 0.25), 0px 0px 8px rgba(51, 51, 51, 0.25);
    border-radius: 8px;
}

.modalInput:focus {
    background: #FFFFFF;
    /* Neutrals/Grey 900 (HiQBlack) (text) */
    border: 1px solid #333333;
    box-shadow: 0px 0px 0px 4px #00AFDB, 0px 0px 8px rgba(51, 51, 51, 0.25);
    border-radius: 8px;


}

.modalInputTheme {
    box-shadow: 0px 0px 1px rgba(51, 51, 51, 0.25), 0px 0px 8px rgba(51, 51, 51, 0.25);
    border-radius: 8px;
}

.modalInputTheme:active {
    /* Neutrals/Grey 900 (HiQBlack) (text) */
    border: 1px solid #333333;
    box-shadow: 0px 0px 1px rgba(51, 51, 51, 0.25), 0px 0px 8px rgba(51, 51, 51, 0.25);
    border-radius: 8px;
}

.modalInputTheme:focus {
    /* Neutrals/Grey 900 (HiQBlack) (text) */
    border: 1px solid #333333;
    box-shadow: 0px 0px 0px 4px #00AFDB, 0px 0px 8px rgba(51, 51, 51, 0.25);
    border-radius: 8px;
}

input[type="datetime-local"]::-webkit-inner-spin-button {
    display: none;
}

.popover {
    min-width: 400px;
    max-height: 400px;
    overflow-y: auto;
}

.popover-body {
    padding: 16px 16px 0px 16px;
}

.detailedListName {
    margin-bottom: 8px;
    font-size: 120%;
}

.detailedListFoodPref {
    margin-left: 8px;
}

.detailedListReservList {
    margin-top: 16px;
}

.ck-editor__editable {
    min-height: 300px;
}

.eventImageArea {
    padding: 24px;
    max-width: 92.5%;
    min-width: 50%;
    height: fit-content;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.eventImage {
    height: fit-content;
    display: flex;
    justify-content: center;
}

.signUpButtons {
    width: 100%;
}

.boxShadow {
    box-shadow: 0px 0px 2px rgba(51, 51, 51, 0.25), 0px 0px 8px rgba(51, 51, 51, 0.1);
    padding-top: 8px;
}

.defaultButton {
    padding: 4px 16px;
    gap: 8px;
    border-radius: 16px;
    min-width: 80px;
    min-height: 25px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.defaultSignUpButton {
    padding: 12px 48px;
    gap: 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    border-radius: 31px;
}

@media screen and (max-width: 1300px) {
    .outline-blackButton {
        background: #FFFFFF;
        border: 2px solid #333333;
        text-align: center;
        color: #333333;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 32px;
    }

    .outline-redButton {
        padding: 8px 32px;
        background: #FFFFFF;
        border: 2px solid red;
        border-radius: 31px;
        text-align: center;
        color: red;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .popover {
        min-width: 300px;
        max-height: 300px;
        overflow-y: auto;
    }

    .popover-body {
        padding: 12px 12px 0px 12px;
    }

    .detailedListName {
        margin-bottom: 8px;
        font-size: 100%;
    }

    .detailedListFoodPref {
        margin-left: 8px;
    }

    .detailedListReservList {
        margin-top: 12px;
    }

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        font-size: 16px;
    }

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        font-size: 16px;
    }

    input[type=text] {
        font-size: 16px;
    }

    .css-1laqsz7-MuiInputAdornment-root {
        transform: scale(0.9);
        margin-left: 0px;
    }

    .modalInput {
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .eventImage {
        max-width: 540px;
        min-width: 300px;
    }

}

@media screen and (min-width: 1300px) and (max-width: 1600px) {
    .eventImage {
        max-width: 600px;
        min-width: 450px;
    }
}

@media screen and (min-width: 1600px) {
    .eventImage {
        max-width: 800px;
        min-width: 500px;
    }
}

@media only screen and (max-width: 768px) {
    .modal.left .modal-dialog {
        min-width: 100vw;
    }

    .outline-blackButton {
        display: block;
        margin-top: 0px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 12px;
        min-width: 80%;
        min-height: 64px;
        font-size: 16px;
    }

    .outline-redButton {
        margin: 0 auto;
        display: block;
        min-width: 80%;
        min-height: 64px;
        font-size: 16px;
    }

    .eventImage {
        max-width: 275px;
        min-width: 200px;
    }
}

@media only screen and (max-width: 576px) {
    .signUpButtons {
        width: auto;
    }

}

@media only screen and (min-width: 1400px) {
    .modal.left .modal-dialog {
        min-width: 50vw;
    }
}